<template>
  <div class="home page">
    <hr class="my-5 header-divider" />

    <div class="pa-4 white">
      <v-progress-linear
        v-if="notificationFormLoading"
        color="primary"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <div v-else>
        <div class="textFields">
          <!-- address for your notification -->
          <!-- add target to notification -->
          <p>الي : </p>
          <v-col cols="6" sm="12" md="6">
        <v-autocomplete
          rounded
          outlined
            :rules="rules"
          hide-details
          clearable
          solo
          label="الدولة"
          class="mb-5 my-2"
          item-text="country"
          item-value="country_code"
          v-model="notificationForm.country_code"
          :items="countries"
        ></v-autocomplete>
        </v-col>
          <v-text-field
            :rules="rules"
            v-model="notificationForm.title_ar"
            counter="100"
            label="عنوان الاشعار بالعربية"
          ></v-text-field>
        </div>

        <!-- detail -->
        <v-textarea
            :rules="rules"
          dense
          outlined
          label="تفاصيل الاشعار بالعربية"
          v-model="notificationForm.body_ar"
        ></v-textarea>

        <!-- SEND BUTTON -->
          <v-spacer></v-spacer>
        <div class="textFields">
          <!-- address for your notification -->
          <v-text-field
            :rules="rules"

            v-model="notificationForm.title_en"
            counter="100"
            label="عنوان الاشعار بالانجليزية"
          ></v-text-field>
        </div>

        <!-- detail -->
        <v-textarea
            :rules="rules"

          dense
          outlined
          label="تفاصيل الاشعار بالانجليزية"
          v-model="notificationForm.body_en"
        ></v-textarea>

        <!-- SEND BUTTON -->
        <v-container>
          <v-row justify="center">
            <v-col cols="12" sm="6" md="4">
              <v-btn @click="sendNotification(notificationForm)" block large color="primary">ارسال للجميع</v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-btn @click="sendToAuthedUsersNotification(notificationForm)" block large color="primary">ارسال الى المسجلين في التطبيق</v-btn>
            </v-col>
          </v-row>
        </v-container>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState , mapActions } from "vuex";

export default {
  name: "notifications",

  data: function () {
    return {
      notificationForm: {
      },
      notificationFormLoading: false,
      rules: [
        v => !!v || "هذا الحقل مطلوب",
      ],
      usersAutocomplete: {
        search: "",
        records: [],
        loading: false,
        selectedUser: null,
      },
    };
  },

  watch: {
    "usersAutocomplete.search"(query) {
      query != "" && this.searchUsersHavingDeviceId(query);
    },
  },
  computed: {
    ...mapState("filters", ["countries"]),
  },
created() {
  this.fetchCountries();
},
  methods: {
    ...mapActions("filters", [
      "fetchCountries",
    ]),

    sendNotification(notificationForm) {
      this.notificationFormLoading = true;
      let payload = JSON.parse(JSON.stringify(notificationForm));
      this.axios
        .post("admin/push-notifications/send", payload)
        .then((response) => {
          this.$bus.$emit("showSnackbar", {
            text: "تم ارسال الاشعار بنجاح",
            color: "success",
          });
        })
        .catch((error) => {
          // console.log(error, error.response);
          this.$bus.$emit("showSnackbar", {
            text: "حدث خطأ ما",
            color: "red darken-3",
          });
        })
        .finally(() => {
          this.notificationFormLoading = false;
        });
    },

    sendToAuthedUsersNotification(notificationForm) {
      this.notificationFormLoading = true;
      let payload = JSON.parse(JSON.stringify(notificationForm));
      this.axios
        .post("admin/push-notifications/sendToAuthedUsers", payload)
        .then((response) => {
          this.$bus.$emit("showSnackbar", {
            text: "تم ارسال الاشعار بنجاح",
            color: "success",
          });
        })
        .catch((error) => {
          // console.log(error, error.response);
          this.$bus.$emit("showSnackbar", {
            text: "حدث خطأ ما",
            color: "red darken-3",
          });
        })
        .finally(() => {
          this.notificationFormLoading = false;
        });
    },

  },
};
</script>
<style scoped>
.textFields {
  margin-bottom: 40px;
}
</style>
