<template>
  <div class="root-Users" width="100%" height="200">
    <v-card flat max-width="100%" tile>
      <v-app-bar class="Users" height="150" color="#F9F9F9" flat>
         <h2 class="accent--text">
      <v-icon color="primary" class="ml-3" large>mdi-bell</v-icon>
 االاشعارات
      <back-button />
    </h2>
    <hr class="my-5 header-divider" />

      </v-app-bar>
    </v-card>

    <div class="table-home">
      <keep-alive>
        <component :is="targetcomponent" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import Client from "../../components/Notifications/Client.vue";
import Chef from "../../components/Notifications/Chef.vue";

export default {
  name: "Notifications",
  data() {
    return {
      targetcomponent: "Client",
    };
  },
  components: {
    Client,
    Chef
  },
};
</script>
<style>
* {
  font-family: "Leelawadee";
}
.root-Users {
  margin: -20px;
}
.Users {
  padding-right: 40px;
  padding-left: 40px;
}
</style>
