<template>
  <div class="home page">
    <h2 class="accent--text">
      Chef Notifications
    </h2>
    <hr class="my-5 header-divider" />

    <div class="pa-4 white">
      <v-progress-linear
        v-if="notificationFormLoading"
        color="primary"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <div v-else>
        <!-- TARGET -->
        <v-radio-group
          v-model="notificationForm.target"
          row
          label="Send to:"
          hide-detailsclass="mb-9"
        >
          <v-radio color="primary" label="Chef" value="all"></v-radio>
        </v-radio-group>

        <div class="textFields">
          <!-- address for your notification -->
          <v-text-field
            v-model="notificationForm.title"
            counter="100"
            hint="Give your notification a title."
            label="Notification Title"
          ></v-text-field>
        </div>

        <!-- detail -->
        <v-textarea
          dense
          outlined
          label="Notification Details"
          v-model="notificationForm.body"
        ></v-textarea>

        <!-- SEND BUTTON -->
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-btn
            @click="sendNotification(notificationForm)"
            large
            color="primary"
            >Send</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "notifications",

  data: function () {
    return {
      notificationForm: {
        target: "all",
        // title: 'cv-sport',
        title: "",
        body: "",
      },
      notificationFormLoading: false,

      usersAutocomplete: {
        search: "",
        records: [],
        loading: false,
        selectedUser: null,
      },
    };
  },

  watch: {
    "usersAutocomplete.search"(query) {
      query != "" && this.searchUsersHavingDeviceId(query);
    },
  },

  methods: {
    sendNotification(notificationForm) {
      this.notificationFormLoading = true;

      let payload = JSON.parse(JSON.stringify(notificationForm));
      // console.log("payloaadddd", payload);
      if (payload.target == "single") {
        if (!this.usersAutocomplete.selectedUser) return;
        payload.id = this.usersAutocomplete.selectedUser.id;
      }

      this.axios
        .post("/dashboard/notify/send/chef", payload)
        .then((response) => {
          this.$bus.$emit("showSnackbar", {
            text: "Notification was successfully sent.",
            color: "success",
          });
        })
        .catch((error) => {
          // console.log(error, error.response);
          this.$bus.$emit("showSnackbar", {
            text: "There was a mistake when sending the notifications.",
            color: "red darken-3",
          });
        })
        .finally(() => {
          this.notificationFormLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.textFields {
  margin-bottom: 40px;
}
</style>
